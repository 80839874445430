import React, { useEffect, useState } from 'react'
import "../../assets/css/eventdetail.css"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { getFormattedDateTime, ifDateIsPassed } from '../Functions';
import { getEventDetailsAction } from '../../actions/getEventDetailsAction';
import { Modal } from 'react-bootstrap';
import EventRSVPForm from './EventRSVPForm';
import BannerWithSearch from '../BannerWithSearch';

const EventDetails = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();


    useEffect(() => {
        dispatch(getEventDetailsAction(localStorage.getItem('token'), JSON.parse(localStorage.getItem('event'))))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])



    const [ShowRSVPModal, setShowRSVPModal] = useState(false);




    const getEventDetails = useSelector(state => state.getEventDetails)
    const { loading_getEventDetailsData, getEventDetailsData } = getEventDetails

    const isTrue = !loading_getEventDetailsData && getEventDetailsData.status == 1;

    var eventData = [];

    if (isTrue) {
        eventData = getEventDetailsData.response.event;
    }

    const closeRSVPModal = () => {
        dispatch(getEventDetailsAction(localStorage.getItem('token'), JSON.parse(localStorage.getItem('event'))))
        setShowRSVPModal(false)
    }

    return (
        <div class="rightside">
            <BannerWithSearch />

            {
                isTrue ?
                    <>
                        <section class="event_detail_section">
                            <div class="inner_row row">
                                <div class="col-xs-7 col-sm-7 col-md-7">
                                    <div class="event_text">
                                        <h3>{eventData.title}</h3>
                                        <p>{eventData.description}</p>
                                    </div>
                                    <div class="event_textdetail">
                                        <h4>Event URL:</h4>
                                        <p><a href={eventData.url}>{eventData.url}</a></p>
                                        <h4>Host:</h4>
                                        <p>{eventData.vendor_name}</p>
                                        <h4>Date:</h4>
                                        <p>{getFormattedDateTime(eventData.date_time, 'DD MMMM YYYY')}</p>
                                        <h4>Time:</h4>
                                        <p>{`${getFormattedDateTime(eventData.date_time, 'h:mm a')} - ${getFormattedDateTime(eventData.end_date_time, 'h:mm a')}`}</p>
                                        <h4>Location:</h4>
                                        <p>{eventData.address}</p>
                                        {
                                            eventData.rsvp_allowed == 1 
                                            && eventData.rsvp == 0 
                                            && !ifDateIsPassed(eventData.date_time) 
                                            ?
                                                <div class="button_wapper_evnet">
                                                    <button class="btn same_btn" onClick={() => { setShowRSVPModal(true) }}>RSVP - Email</button>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div class="col-xs-5 col-sm-5 col-md-5">
                                    <div class="img_box_event">
                                        {
                                            eventData.image != null ?
                                                <img src={eventData.image} alt={eventData.title} />
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            ShowRSVPModal ?
                                <EventRSVPForm closeRSVPModal={closeRSVPModal} event_id={JSON.parse(localStorage.getItem('event')).id} />
                                : null
                        }
                        
                    </>
                    :
                    null
            }
        </div>


    );


};



export default EventDetails;